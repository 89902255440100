<template>
    <div class="shop-detail-class">
        <section v-loading="loading" class="center-detail">
            <!-- 返回按钮 -->
            <!--<div @click="returnBack()" class="return-btn">
                <img src="@/assets/images/storeManage/left_arrow.png" alt/>
                <span>返回</span>
            </div>-->
            <!-- 店铺名称 -->
            <div class="store-name font-weight-bold">
                <span>{{storeName}}</span>
                <span class="line">|</span>
                <span>店铺详情</span>
            </div>
            <!-- 基础信息 -->
            <div class="basis-info-class">
                <p>基础信息</p>
                <div>
                    <!--<div class="basis-list" :key="data.name" v-for="data in basisInfoList">
                      <span class="name">{{data.name}}</span>
                      <span class="content">{{data.content}}</span>
                    </div>-->
                    <div class="basis-list">
                        <span class="name">后台地址</span>
                        <span class="content">
                            <a :href="storeAdminSite" target="_blank">{{storeAdminSite}}</a>
                        </span>
                    </div>
                    <div class="basis-list">
                        <span class="name">店铺主域名</span>
                        <span class="content">
                            <a v-if="storeDomain" :href="storeDomain" target="_blank">{{storeDomain}}</a>
                        </span>
                    </div>
                    <div class="basis-list">
                        <span class="name">添加时间</span>
                        <span class="content">{{createTime}}</span>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import {storeManagement} from "@/assets/js/api";
    import {handleResponseErrors} from "@/assets/js/utils";
    import {mapState} from "vuex";

    export default {
        name: "shopDetail",
        props: [
            "storeName"
        ],
        data() {
            return {
                loading: false,
                // storeName : '',
                loginEmail: '',
                storeAdminSite: '',
                storeDomain: '',
                createTime: '',
                basisInfoList: [
                    {
                        name: "后台地址",
                        content: "https://funpinpin.com"
                    },
                    {
                        name: "店铺主域名",
                        content: "https://funpinpin.com"
                    },
                    {
                        name: "添加时间",
                        content: "2020年08月13日 16:12"
                    }
                ]
            };
        },
        created() {
            this.getStoreDetail()
        },
        mounted() {
        },
        computed: {...mapState(["userMsg"])},
        methods: {
            async getStoreDetail() {
                this.loading = true
                try {
                    let data = this.storeName
                    let res = await storeManagement.getStoreDetail({}, data);
                    if (res.code !== 0) {
                        this.loading = false
                        handleResponseErrors(res.code, res.msg);
                        return;
                    }
                    else {
                        this.loading = false
                        // this.storeName = res.store_name
                        this.loginEmail = res.login_email
                        this.storeAdminSite = res.store_admin_site
                        this.storeDomain = res.store_domain
                        this.createTime = res.create_time
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            returnBack() {
                this.$router.push({path: 'storeList'})
            }
        },
    };
</script>

<style scoped lang="scss">
    .shop-detail-class {
        .return-btn {
            cursor: pointer;
        }
        .center-detail {
            max-width: 1166px;
            margin: auto;
            margin-top: 51px
        }
        .store-name {
            color: #312F2E;
            font-size: 18px;
            margin-bottom: 10px;
            .line {
                color: #312f2e;
                padding: 0 10px;
            }
        }
        .basis-info-class {
            p {
                font-size: 16px;
                color: #9B9B9B;
                font-weight: 400;
                border-bottom: 1px solid #e2d6cb;
                padding-bottom: 20px;
            }
            .basis-list {
                font-size: 16px;
                font-weight: 400;
                padding: 19px 0;
                border-bottom: 1px solid #e2d6cb;
                display: flex;
                align-items: baseline;
                justify-content: center;
                .name {
                    color: #312f2e;
                    width: 120px;
                    font-weight: 400;
                    display: inline-block;
                }
                .content {
                    color: #312F2E;
                    display: inline-block;
                    width: calc(100% - 120px);
                    font-weight: 400;
                    a {
                        color: #1890FF;
                        line-height: 27px;
                    }
                }
            }
        }
        .font-weight-bold {
            font-weight: bold !important;
        }
    }
</style>
